import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styled from '@emotion/styled';

// Utils
import getWindow from 'Utils/get-window';
import { USAGE_NAV_FROMS, USAGE_DISPLAY_STYLES } from 'Utils/analytics/usage';
import { AppTheme } from 'Webapp/enums';

import { SPACING } from 'Style/spacing';
import { BODY_TYPES, UI_HEADING_TYPES } from 'Style/typography';
import { COLORS, DIVIDER_COLORS } from 'Style/colors';

// Components
import AnchoredDialog, {
  ANCHORED_DIALOG_POSITION_X,
  ANCHORED_DIALOG_POSITION_Y,
} from 'ComponentLibrary/utility/anchored-dialog';
import { StyleVariations } from 'Webapp/shared/app/components/button';
import SignupButton from 'Webapp/shared/app/containers/signup-button';
import QrCode from 'Webapp/shared/app/components/signup-cta/qr-code';
import PlayStore from 'Webapp/shared/app/components/signup-cta/play-store';
import AppStore from 'Webapp/shared/app/components/signup-cta/app-store';
import WebLink from 'Webapp/shared/app/components/web-link';
import { NavFromContext } from 'ComponentLibrary/context';

import withT from 'ComponentLibrary/hocs/withT';
import withSignupCta from '../hocs/withSignupCta';

import connector from 'Utils/connector';
import connectResponsive from 'Webapp/shared/app/connectors/connectResponsive';
import connectLanguage from 'Webapp/shared/app/connectors/connectLanguage';
import connectModal from 'Webapp/shared/app/connectors/connectModal';
import connectTheme from 'Webapp/shared/app/connectors/connectTheme';

const SHOW_CTA_DELAY_MS = 2000;

const StyledWebLink = styled(WebLink)({
  display: 'block',
  lineHeight: 0,
});

const Content = styled.div({
  display: 'grid',
  gridTemplateColumns: '45% 55%',
  gridTemplateRows: 'auto',
  paddingLeft: SPACING.LARGE,
});

const ContentLeft = styled.div({
  display: 'grid',
  gap: SPACING.LARGE,
  justifyContent: 'space-between',
  padding: `${SPACING.LARGE} ${SPACING.BASE4X} ${SPACING.BASE} 0`,
  borderRight: `1px solid ${DIVIDER_COLORS.primary}`,
});

const Heading = styled.h2(UI_HEADING_TYPES.MEDIUM, { textAlign: 'center ' });

const SubHeading = styled.h4(BODY_TYPES.LARGE_STANDARD, {
  fontSize: '16px',
  letterSpacing: '-0.1px',
  textAlign: 'center',
});

const Apps = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  marginLeft: SPACING.XLARGE,
  gap: SPACING.BASE,
});

const QrCodeWrapper = styled.div({
  width: '104px',
  display: 'flex',
  alignItems: 'center',
});

const StyledQRCode = styled(QrCode)({
  width: '104px',
  height: '104px',
});

const StoresWrapper = styled.div({
  display: 'grid',
  gap: SPACING.MEDIUM,
});

const PlayStoreWrapper = styled.div({
  width: '134px',
});

const AppStoreWrapper = styled.div({
  width: '118px',
});

const Instructions = styled.p(BODY_TYPES.SMALL_EMPHASIS_2);

class SignupButtonCtaAnchoredDialog extends Component {
  constructor(props) {
    super(props);
    this.state = { showCta: false };
  }

  componentDidMount() {
    if (this.props.readyForMetrics && this.props.shouldShowSignupCta) {
      this.startShowTimeout();
    }
  }

  componentDidUpdate(prevProps) {
    const { readyForMetrics, shouldShowSignupCta } = this.props;

    // Transitioning to "ready for metrics"
    if (!prevProps.readyForMetrics && readyForMetrics && shouldShowSignupCta) {
      this.startShowTimeout();
    }

    // hide CTA if the app state dictates
    if (!shouldShowSignupCta && this.state.showCta) {
      this.setState({ showCta: false });
    }
  }

  componentWillUnmount() {
    if (this.timeout) {
      getWindow().clearTimeout(this.timeout);
    }
  }

  startShowTimeout = () => {
    this.timeout = getWindow().setTimeout(() => {
      this.props.usageTrackNavBarEdu();
      this.setState({ showCta: true });
    }, SHOW_CTA_DELAY_MS);
  };

  onDismiss = () => {
    this.props.usageTrackDialogCloseButtonTap(
      USAGE_DISPLAY_STYLES.NAV_BAR_EDU_CTA,
    );
    this.setState({ showCta: false });
  };

  render() {
    const { appTheme, t } = this.props;
    const dialogStyleVariation = this.props.isWideLargeScreen
      ? AnchoredDialog.styleVariations.NONE
      : AnchoredDialog.styleVariations.FULL_WIDTH;

    const className = classnames('signup-cta', {
      'signup-cta--full-width':
        dialogStyleVariation === AnchoredDialog.styleVariations.FULL_WIDTH,
    });

    return (
      (this.state.showCta && (
        <AnchoredDialog
          className={className}
          targetRef={this.props.targetRef}
          fixed
          styleVariation={dialogStyleVariation}
          positionX={ANCHORED_DIALOG_POSITION_X.RIGHT}
          positionY={ANCHORED_DIALOG_POSITION_Y.BELOW}
          showCloseButton
          onDismiss={this.onDismiss}
        >
          <NavFromContext.Provider value={USAGE_NAV_FROMS.NAV_BAR_EDU_CTA}>
            <Content>
              <ContentLeft>
                <Heading>{t('popup_cta_header')}</Heading>
                <SubHeading>{t('popup_cta_subheader')}</SubHeading>
                <SignupButton
                  name="signup-cta-singup"
                  label={t('popup_cta_button')}
                  styleVariation={StyleVariations.PRIMARY}
                  displayStyle={USAGE_DISPLAY_STYLES.NAV_BAR_EDU_CTA}
                />
              </ContentLeft>
              <Apps>
                <StoresWrapper>
                  <Instructions>{t('popup_cta_app_instructions')}</Instructions>
                  <PlayStoreWrapper>
                    <StyledWebLink href="https://app.adjust.com/7flphqz">
                      <PlayStore />
                    </StyledWebLink>
                  </PlayStoreWrapper>
                  <AppStoreWrapper>
                    <StyledWebLink href="https://app.adjust.com/7flphqz">
                      <AppStore />
                    </StyledWebLink>
                  </AppStoreWrapper>
                </StoresWrapper>
                <QrCodeWrapper>
                  <StyledQRCode
                    color={
                      appTheme === AppTheme.DARK
                        ? COLORS.staticBlack
                        : COLORS.staticWhite
                    }
                    backgroundColor={
                      appTheme === AppTheme.DARK
                        ? COLORS.staticWhite
                        : COLORS.staticBlack
                    }
                  />
                </QrCodeWrapper>
              </Apps>
            </Content>
          </NavFromContext.Provider>
        </AnchoredDialog>
      )) ||
      null
    );
  }
}

SignupButtonCtaAnchoredDialog.propTypes = {
  targetRef: PropTypes.object,
  isLargeScreen: PropTypes.bool.isRequired,
  isWideLargeScreen: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  readyForMetrics: PropTypes.bool.isRequired,
  shouldShowSignupCta: PropTypes.bool.isRequired,
  usageTrackNavBarEdu: PropTypes.func.isRequired,
  usageTrackDialogCloseButtonTap: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  appTheme: PropTypes.string,
};

export default connector(
  connectResponsive,
  connectLanguage,
  connectModal,
  connectTheme,
)(withT(withSignupCta(SignupButtonCtaAnchoredDialog)));
